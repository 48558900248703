<template>
  <div>
    <input
        v-model="input"
        :name="name"
        :placeholder="placeholder"
        :type="type"
        class="custom-input"
    >
  </div>
</template>

<script>
export default {
  name: "CustomInput",
  data() {
    return {
      input: this.value,
    };
  },
  watch: {
    // при каждом изменении `select` эта функция будет запускаться
    input(newQuestion) {
      this.$emit('change', newQuestion)
    },
    value(newValue) {
      this.input = newValue
    },
  },
  props: {
    type: String,
    name: String,
    value: String,
    placeholder: String
  }
}
</script>

<style scoped>
.custom-input {
  margin-top:15px;
  width: 350px;
  border-radius: 8px;
  background: #f2f3f4;
  border:none;
  padding: 5px 15px;
  cursor:text;
  font-size: 15px;
  height: 54px;

}
.custom-input:focus-visible {
  outline:none;
}
@media (max-width: 768px) {
  .custom-input {
    width:100%;
  }
}
</style>
