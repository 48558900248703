import Axios from 'axios';

export const user = {
    state: () => ({
        isAuth: false,
        token: '',
        dateOfBirth: {},
        userData: {},
        loadUserData: false,
    }),
    mutations: {
        setLoadUserData(state, payload) {
            state.loadUserData = payload
        },
        setIsAuthUser(state, payload) {
            state.isAuth = payload
        },
        setToken(state, payload) {
            state.token = payload
        },
        setUser(state, payload) {
            state.userData = payload
        },
    },
    actions: {
        clearAuth(context) {
            context.commit('setToken', '');
            context.commit('setIsAuthUser', false);
            localStorage.removeItem('token');
        },
        registerUsers(context, params) {

            var formData = new FormData();

            for (var name in params) {
                formData.append(name, params[name])
            }

            context.dispatch('clearAuth');

            return Axios
                .post(`${process.env.VUE_APP_AJAX_HOST}/api/register`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
        },
        loginUsers(context, params) {
            context.commit('setToken', '');
            return Axios.post(`${process.env.VUE_APP_AJAX_HOST}/api/login`, params).then((response) => {
                localStorage.setItem('token', response.data.plainTextToken);
                context.commit('setToken', response.data.plainTextToken);
                context.commit('setIsAuthUser', true);
                return response;
            })
        },
        profileUser(context, params) {
            context.commit('setLoadUserData', true);
            context.commit('setUser', {});
            Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/profile`, params).then((response) => {
                context.commit('setUser', response.data);
                context.commit('setLoadUserData', false);
            });
        },
        restorePassword(context, params) {
            return Axios.post(`${process.env.VUE_APP_AJAX_HOST}/api/restore-password`, params)
        },
        setAxiosError(context) {
            Axios.interceptors.response.use(
                function(response) {
                    // Do something with response data
                    return response;
                },
                function(error) {
                    // Do something with response error
                    if (
                        error.response.status === 401 &&
                        error.response.config &&
                        !error.response.config.__isRetryRequest
                    ) {
                        context.commit("setToken", "");
                        //this.$router.push("/login");
                    }
                    return Promise.reject(error);
                }
            );
        },
        setAxiosToken(context) {
            const token = localStorage.getItem("token");

            if (token) {
                Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                context.commit("setToken", token);
                context.commit('setIsAuthUser', true);
            } else {
                //this.$router.push("/login");
                context.commit("setToken", '');
                context.commit('setIsAuthUser', false);
            }
        },
        resetPassword(context, params) {
            return Axios.post(`${process.env.VUE_APP_AJAX_HOST}/api/reset-password`, params)
        },

    },
    getters: {
        getIsAuthUser(state) {
            return state.isAuth
        },
        getToken(state) {
            return state.token
        },
        getUser(state) {
            return state.userData
        },
        getLoadUserData(state) {
            return state.loadUserData
        },
    }
}