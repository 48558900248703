<template>
  <div class="selector" tabindex="1">
    <div class="selector-wrapper">
      <div class="selector-text">{{ title }}</div>
      <div class="selector-value">
        <input
          v-model="inputSelect"
          class="selector-value-input"
          type="radio"
          id="category-default"
          :name="title"
          value="category-default"
          checked="checked"
        />
        <p class="selector-value-input-text">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-dash-lg"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
            />
          </svg>
        </p>
      </div>
      <div class="selector-value" v-for="item in select" :key="item.id">
        <input
          v-model="inputSelect"
          :value="item.id"
          class="selector-value-input"
          type="radio"
          :name="title"
          :id="item.value"
        />
        <p class="selector-value-input-text">{{ item.value }}</p>
      </div>
    </div>
    <div class="selector-chevron">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#9D9FA3"
        class="bi bi-chevron-down"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
        />
      </svg>
    </div>
  </div>
  <div class="selector-list">
    <li v-for="item in select" :key="item.id">
      <label
        class="selector-option"
        :for="item.value"
        aria-hidden="aria-hidden"
        >{{ item.value }}</label
      >
    </li>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  data() {
    return {
      inputSelect: this.value,
    };
  },
  watch: {
    // при каждом изменении `select` эта функция будет запускаться
    inputSelect(newQuestion) {
      this.$emit('change', newQuestion)
    },
    value(newValue) {
        this.inputSelect = newValue
    },
  },
  props: {
    title: String,
    value: String,
    select: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.selector {
  position: relative;
  display: flex;
  align-items: center;
  width: 350px;
  background: rgba(201, 204, 209, 0.24);
  border-radius: 8px;
  padding: 5px 15px;
  cursor: pointer;
  margin-top: 15px;
  height: 54px;
}
.selector-wrapper {
  width: 320px;
}
.selector-text {
  font-size: 13px;
  color: #71747a;
}
.selector-value {
  font-size: 15px;
  line-height: 24px;
  color: #171a1f;
}
.selector-chevron {
  width: 30px;
  text-align: center;
}
.selector-list {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08), 0px 0px 48px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  width: 350px;
}
label.selector-option {
  margin: 0;
    padding: 10px 15px;
  display: block;
  cursor: pointer;
}
.selector-list {
  list-style-type: none;
  padding: 8px 0;
}
.selector-list li {
  z-index: 999;
}
.selector-list li:hover {
  background: #f2f3f5;
}
.selector-value-input {
  display: none;
}
.selector-value-input-text {
  display: none;
  margin: 0;
}
.selector-list {
  opacity: 0;
  position: absolute;
  z-index: 999;
  animation-name: HideList;
  animation-duration: 0.3s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: step-start;
}
.selector:focus + .selector-list {
  opacity: 1;
  animation-name: none;
}
.selector-value-input:checked + .selector-value-input-text {
  display: block;
}

@keyframes HideList {
  from {
    transform: scaleY(1);
    display: none;
  }
  to {
    transform: scaleY(0);
    display: block;
  }
}

@media (max-width: 768px) {
  .selector {
    width: 100%;
  }
  .selector-wrapper {
    width: 100%;
  }
  .selector-list {
    width: calc(100% - 30px);
  }
}
</style>
