<template>
  <button class="modal-secondary-button">
    <slot>

    </slot>
  </button>
</template>

<script>
export default {
  name: "ModalSecondaryButton"
}
</script>

<style scoped>
.modal-secondary-button {
  border: 2px solid #71747A;
  border-radius: 7px;
  font-size: 14px;
  color: #71747A;
  background-color:#FFFFFF;
  padding:8px 30px;
  width: 45%;
  outline:none;
}
.modal-secondary-button:hover {
  border: 2px solid #2C2C2C;
  color: #2C2C2C;
}
@media (max-width: 768px) {
  .modal-secondary-button {
    padding:8px 10px;
    width:35%
  }
}
</style>