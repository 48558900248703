import Axios from 'axios';
export const news = {
    state: () => ({
        newsData: {},
        newsDetailData: {}
    }),
    mutations: {
        setNewsData(state, payload) {
            state.newsData = payload
        },
        setNewsDetailData(state, payload) {
            state.newsDetailData = payload
        },
    },
    actions: {
        newsData(context, params) {
            Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/get-news`, {
                params: params,

            }).then((response) => {
                context.commit('setNewsData', response.data);
            });
        },
        newsDetailData(context, params) {
            Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/get-news-detail`, {
                params: params,

            }).then((response) => {
                context.commit('setNewsDetailData', response.data);
            });
        },
    },
    getters: {
        getNewsData: (state) => {
            return state.newsData
        },
        getNewsDetailData: (state) => {
            return state.newsDetailData
        }
    }
}
