<template>

  <b-container>
    <div :class="{'main-container': mainContainer}">
      <router-view/>
    </div>
  </b-container>

</template>

<script>
export default {
  name: "Container",
  computed: {
      mainContainer() {
          return this.$route.meta.mainContainer !== undefined 
                    ? this.$route.meta.mainContainer : true;
      }
  }
}
</script>

<style scoped>

</style>