import Axios from 'axios';
export const history = {
    state: () => ({
        historyData: {},
        pagination : {}
    }),
    mutations: {
        setHistoryData(state, payload) {
            state.historyData = payload
        },
        setPagination(state, payload) {
            state.pagination = payload
        }
    },
    actions: {
        historyData(context, params) {
            let linkToUse = `${process.env.VUE_APP_AJAX_HOST}/api/get-sale-history`;

            if(params.link){
                linkToUse = params.link;
            }

            Axios.get(linkToUse, {
                params: params,

            }).then((response) => {
                if (Object.keys(response.data.data).length > 0) {
                    context.commit('setHistoryData', response.data.data);
                }else{
                    context.commit('setHistoryData', []);
                }
                response.data.links.pop();
                response.data.links.shift();
                context.commit('setPagination', response.data);

            });
        },
    },
    getters: {
        getHistoryData: (state) => {
            return state.historyData
        },
        getPagination: (state) => {
            return state.pagination
        }
    }
}
