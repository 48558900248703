import Axios from 'axios';
export const sale = {
    state: () => ({
        categorySelect: {},
        productTypeSelect: {},
        markSelect: {},
        date: {},
        saleInformation: {},
        preloaderSaleInformation: false,
        digitalCode: '',
        errors: [],
    }),
    mutations: {
        setSale(state, payload) {
            state.sale = payload
        },
        setErrors(state, payload) {
            state.errors = payload
        },
        setCategorySelect(state, payload) {
            state.categorySelect = payload
        },
        setProductTypeSelect(state, payload) {
            state.productTypeSelect = payload
        },
        setMarkSelect(state, payload) {
            state.markSelect = payload
        },
        setSaleInformation(state, payload) {
            state.saleInformation = payload
        },
        setPreloaderSaleInformation(state, payload) {
            state.preloaderSaleInformation = payload
        },
        setDigitalCode(state, payload) {
            state.digitalCode = payload
        },
    },
    actions: {
        getSaleFormParams(context, params) {
            Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/get-form-params`, {
                params: params,
            }).then(() => {}).then((response) => {
                context.commit('setCategorySelect', response.data.categorySelect);
                context.commit('setProductTypeSelect', response.data.productTypeSelect);
                context.commit('setMarkSelect', response.data.markSelect);
            });
        },
        saleAddCheck(context, params) {
            Axios.get(`${process.env.VUE_APP_AJAX_HOST}/sanctum/csrf-cookie`)
                .then(() => {});
            return Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/sale-add`, params)
        },
        checkSale(context, params) {
            Axios.get(`${process.env.VUE_APP_AJAX_HOST}/sanctum/csrf-cookie`)
                .then(() => {});

            context.commit('setSaleInformation', {})
            context.commit('setErrors', [])

            context.commit('setPreloaderSaleInformation', true);

            return Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/sale-check`, { params: params })
                .then((response) => {

                    var data = response.data;

                    if (data.errors.length > 0) {
                        context.commit('setErrors', [data.errors]);
                    } else {
                        context.commit('setSaleInformation', data);
                    }

                    context.commit('setPreloaderSaleInformation', false);
                    // return true;
                }).catch((error) => {
                    var errorMessage;

                    if (error != undefined &&
                        error.response != undefined &&
                        error.response.data != undefined &&
                        error.response.data.message != undefined) {
                        errorMessage = [
                            [error.response.data.message]
                        ]
                    } else {
                        errorMessage = [
                            ['Произошла ошибка на сервере, попробуйте позже!']
                        ]
                    }

                    context.commit('setErrors', errorMessage);
                    context.commit('setPreloaderSaleInformation', false);
                });
        },
        saleRegistration(context) {
            context.dispatch("startLoading");
            return Axios.post(`${process.env.VUE_APP_AJAX_HOST}/api/sale/register`)
                .then((response) => {
                    if (response.data.data !== undefined) {
                        context.commit('sendBasket', response.data.data);
                    }
                    return response;
                })
                .finally(() => {
                    context.dispatch("stopLoading");
                });
        },
        setDigitalCode(context, params) {
            context.commit('setDigitalCode', params);
        },
        clearDigitalCode(context) {
            context.commit('setDigitalCode', '');
            context.commit('setSaleInformation', {});
            context.commit('setErrors', []);
        },
    },
    getters: {
        categorySelect: (state) => {
            return state.categorySelect
        },
        productTypeSelect: (state) => {
            return state.productTypeSelect
        },
        markSelect: (state) => {
            return state.markSelect
        },
        getSaleInformation: (state) => {
            return state.saleInformation
        },
        getPreloaderSaleInformation: (state) => {
            return state.preloaderSaleInformation
        },
        getErrors: (state) => {
            return state.errors
        },
        getDigitalCode: (state) => {
            return state.digitalCode
        },
    }
}