import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faSearch,
    faAlignJustify,
    faSortDown,
    faBookOpen,
    faStar,
    faChevronDown,
    faLongArrowAltRight,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faQuestionCircle,
    faChevronRight,
    faChevronLeft,
    faCheckCircle,
    faTimes,
    faBolt,
    faImages,
    faRedoAlt,
    faChevronUp,
    faTimesCircle,
    faInfoCircle,
    faEye,
    faEyeSlash,
    faPlusCircle


} from '@fortawesome/free-solid-svg-icons';

library.add(faSearch)
library.add(faAlignJustify)
library.add(faSortDown)
library.add(faBookOpen)
library.add(faStar)
library.add(faChevronDown)
library.add(faLongArrowAltRight)
library.add(faArrowAltCircleLeft)
library.add(faArrowAltCircleRight)
library.add(faQuestionCircle)
library.add(faChevronRight)
library.add(faChevronLeft)
library.add(faCheckCircle)
library.add(faTimes)
library.add(faBolt)
library.add(faImages)
library.add(faRedoAlt)
library.add(faChevronUp)
library.add(faTimesCircle)
library.add(faInfoCircle)
library.add(faEye)
library.add(faEyeSlash)
library.add(faPlusCircle)