<template>
  <div class="modal-body">
    <slot>

    </slot>
  </div>
</template>

<script>
export default {
  name: "ModalBodyLeft"
}
</script>

<style scoped>
.modal-body {
  padding:20px 30px;
  font-size:14px;
  display:flex;
  flex-direction: column;
}
</style>