<template>
  <div class="mt-5">
    <button class="secondary-button">
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "SecondaryButton"
}
</script>

<style scoped>

.secondary-button {
  border: 2px solid #71747A;
  border-radius: 7px;
  font-size: 18px;
  color: #71747A;
  background-color:#FFFFFF;
  width:350px;
  height:60px;
}
.secondary-button:hover {
  border: 2px solid #2C2C2C;
  color: #2C2C2C;
}
@media (max-width: 768px) {
  .secondary-button {
    width:100%;
  }
}
</style>