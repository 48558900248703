<template>
  <div class="modal-body">
    <slot>

    </slot>
  </div>
</template>

<script>
export default {
  name: "ModalBodyOnly"
}
</script>

<style scoped>
.modal-body {
  padding:40px 30px;
  font-size:14px;
}
</style>