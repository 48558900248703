<template>
  <router-link v-for="route in routes" :key="route.path" :to="route.path">
    {{ route.name }}
  </router-link>

  <Sidebar>
    <Burger class="close-mobile-menu" />
    <div>
      <router-link
        to="/"
        @click="closeSidebarPanel"
        class="logo-link left-menu-logo"
        ><img src="../images/logo.png"
      /></router-link>
    </div>
    <template v-if="getIsAuthUser === false">
      <div class="mobile-main-links">
        <div>
          <modal-main-button
            class="mobile-registration-button"
            @click="moveToRegistration"
            >Зарегистрироваться</modal-main-button
          >
        </div>
        <div class="login-block mt-4">
          <router-link to="/login/" @click="closeSidebarPanel"
            >Вход</router-link
          >
          <span class="material-icons pl-2 arrow-right-icon">east</span>
        </div>
        <div class="mobile-links">
          <router-link
            to="/about/"
            @click="closeSidebarPanel"
            active-class="active"
            class="mobile-menu-link"
            >О программе</router-link
          >
          <router-link
            to="/faq/"
            @click="closeSidebarPanel"
            active-class="active"
            class="mobile-menu-link"
            >Вопрос-ответ</router-link
          >
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mobile-main-links-auth">
        <div class="d-flex align-items-center mr-1 mt-3">
          <div class="avatar" v-if="Object.keys(getUser).length > 0">
            {{ getUser.name.charAt(0) }}{{ getUser.surname.charAt(0) }}
          </div>
          <div>
            <div class="profile-name">
              <a
                class="mobile-profile-link"
                v-if="Object.keys(getUser).length > 0"
              >
                {{ getUser.name }} {{ getUser.surname }}
              </a>
            </div>
            <div
              class="profile-props"
              v-if="getSummarizingDataResult != undefined"
            >
              {{ getSummarizingDataResult.current_level }}
              <span class="profile-props-value">{{
                getSummarizingDataResult.active_points
              }}</span>
              <span class="material-icons star-icon-small">star</span>
            </div>
          </div>
        </div>
        <div class="mobile-links">
          <!--            <router-link to="/news/" @click="closeSidebarPanel" active-class="active" class="mobile-menu-link">Новости</router-link>-->
          <router-link
            to="/rules/"
            @click="closeSidebarPanel"
            active-class="active"
            class="mobile-menu-link"
            >Правила начисления</router-link
          >
          <router-link
            to="/sale/add/"
            @click="closeSidebarPanel"
            active-class="active"
            class="mobile-menu-link"
            >Заработать баллы</router-link
          >
          <a
            href="javascript:void(0);"
            @click="openMgc"
            class="mobile-menu-link"
            >Блага</a
          >
          <router-link
            to="/summarizing/"
            @click="closeSidebarPanel"
            active-class="active"
            class="mobile-menu-link"
            ><span>Подведение итогов</span></router-link
          >
          <router-link to="/" @click="clearAuth" class="mobile-menu-link"
            >Выйти</router-link
          >
        </div>
      </div>
    </template>
    <div class="mobile-contacts">
      <a href="mailto:mail@example.ru" class="main-color contacts mr-4">
        <span class="material-icons pr-2">mail</span>
        mail@example.ru</a
      >
      <a href="tel:+79990123456" class="main-color contacts">
        <span class="material-icons pr-2">call</span>
        +7 (999) 012-34-56</a
      >
    </div>
  </Sidebar>

  <b-nav class="navbar">
    <div class="container justify-content-between">
      <div class="navbar-inner">
        <div class="mobile-menu-icon">
          <Burger />
        </div>
        <router-link to="/" class="menu-link logo-link ml-0">
          <img src="../images/logo.png" />
        </router-link>
        <template v-if="getIsAuthUser">
          <!--          <router-link to="/news/" active-class="active" class="menu-link">Новости</router-link>-->
          <router-link to="/rules/" active-class="active" class="menu-link"
            >Правила начисления</router-link
          >
          <router-link to="/sale/add/" active-class="active" class="menu-link"
            >Заработать баллы</router-link
          >
          <a href="javascript:void(0);" @click="openMgc" class="menu-link"
            >Блага</a
          >
        </template>
        <template v-else>
          <router-link to="/about/" active-class="active" class="menu-link"
            >О программе</router-link
          >
          <router-link to="/faq/" active-class="active" class="menu-link"
            >Вопрос-ответ</router-link
          >
        </template>
      </div>
      <template v-if="getIsAuthUser">
        <div
          class="d-flex align-items-center mr-1"
          :class="{ 'load-user': loadUser }"
        >
          <template v-if="loadUser">
            <div class="basket-load"></div>
          </template>
          <template v-else-if="basketTotal.totalItem > 0">
            <router-link to="/sale/basket/" class="basket-link">
              <div
                class="
                  basket-small
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <span class="material-icons-outlined">shopping_cart</span>
                <div class="basket-small__total-items">
                  {{ basketTotal.totalItem }}
                </div>
              </div>
            </router-link>
          </template>
          <template v-else>
            <router-link to="/sale/basket/" class="basket-link">
              <div
                class="
                  basket-small basket-empty-small
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <span class="material-icons-outlined">shopping_cart</span>
                <div class="basket-small__total-items">Пусто</div>
              </div>
            </router-link>
          </template>
          <template v-if="loadUser">
            <div class="avatar avatar-load" ></div>
          </template>
          <template v-else>
            <div class="avatar" v-if="Object.keys(getUser).length > 0">
              {{ getUser.name.charAt(0) }}{{ getUser.surname.charAt(0) }}
            </div>
          </template>
          
          <div v-if="loadUser">
            <div class="profile-name-load"></div>
            <div class="profile-props-load mt-1"></div>
          </div>
          <div v-else>
            <div class="profile-name" v-if="Object.keys(getUser).length > 0">
              <a
                class="dropdown-toggle"
                type="button"
                id="profile-name"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ getUser.name }} {{ getUser.surname }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="profile-name">
                <li>
                  <a class="dropdown-item" href="/#/summarizing/"
                    >Подведение итогов</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" @click="clearAuth" href="#">Выйти</a>
                </li>
              </ul>
            </div>
            <div v-if="getSummarizingDataResult != undefined"
              class="profile-props">
              {{ getSummarizingDataResult.current_level }}
              <span class="profile-props-value">{{
                getSummarizingDataResult.active_points
              }}</span>
              <span class="material-icons star-icon-small">star</span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="auth-block">
          <div class="login-block">
            <router-link to="/login/">Вход</router-link>
            <span class="material-icons pl-2 arrow-right-icon">east</span>
          </div>
          <modal-main-button
            class="registration-button"
            @click="$router.push({ name: 'Registration' })"
            >Зарегистрироваться</modal-main-button
          >
        </div>
      </template>
    </div>
  </b-nav>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      authorized: false,
    };
  },
  methods: {
    moveToRegistration() {
      this.closeSidebarPanel();
      this.$router.push({ name: "Registration" });
    },
    closeSidebarPanel() {
      this.$store.dispatch("toggleNav");
    },
    openMgc() {
      this.$store.dispatch("openMgc");
    },
    profileUser() {
      this.$store.dispatch("profileUser");
    },
    summarizingDataResult() {
      this.$store.dispatch("summarizingDataResult");
    },
    clearAuth() {
      this.$store.dispatch("clearAuth");
    },
  },
  mounted() {
    if (this.$store.getters.getIsAuthUser === true) {
      this.profileUser();
      this.summarizingDataResult();
      this.$store.dispatch("getBasketData");
      this.$store.dispatch("getAvailableData");
    }
  },
  computed: {
    getIsAuthUser() {
      return this.$store.getters.getIsAuthUser;
    },
    loadUser() {
      return this.$store.getters.getLoadUserData;
    },
    getUser() {
      return this.$store.getters.getUser;
    },
    getSummarizingDataResult() {
      return this.$store.getters.getSummarizingDataResult;
    },
    basketTotal() {
      return this.$store.getters.gendBasketTotal;
    },
  },
};
</script>

<style scoped>
.arrow-right-icon {
  color: #71747a;
}
.star-icon-small {
  color: #ffc107;
  font-size: 18px;
}
.mobile-menu-link.active span {
  color: #171a1f;
  border-bottom: 2px solid #171a1f;
  padding-bottom: 5px;
  border-radius: 0px 0px 2px 2px;
}
.left-menu-logo {
  min-height: 39px;
}
.mobile-profile-link {
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  color: #171a1f;
}
.mobile-main-links {
  min-height: calc(100% - 130px);
}
.mobile-main-links-auth {
  min-height: calc(100% - 150px);
}
.mobile-contacts {
  display: flex;
  flex-direction: column;
}
.mobile-contacts a {
  padding: 10px 0;
  font-weight: bold;
  display: flex;
}
.mobile-links {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.mobile-links a {
  font-size: 15px;
  color: #71747a;
  font-weight: bold;
  padding: 10px 0;
}
.mobile-registration-button {
  width: 200px;
  margin-top: 25px;
}
.close-mobile-menu {
  position: absolute;
  top: 15px;
  right: 15px;
}
.auth-block {
  display: flex;
  align-items: center;
}
.login-block {
  margin-right: 50px;
  display: flex;
}
.login-block a {
  color: #71747a;
}
.login-block a:hover {
  text-decoration: none;
  color: #3663f2;
}
.registration-button {
  width: auto;
}
.navbar {
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08), 0px 0px 48px rgba(0, 0, 0, 0.04);
}
.navbar-inner {
  display: flex;
  align-items: center;
}
.dropdown-menu {
  border: none !important;
  border-radius: 9px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08), 0px 0px 48px rgba(0, 0, 0, 0.04);
}
.menu-link {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #71747a;
  margin: 0px 20px;
  padding: 20px 0;
}
.menu-link:hover {
  text-decoration: none;
  color: #3663f2;
}
.menu-link.active {
  color: #171a1f;
  border-bottom: 2px solid #171a1f;
  border-radius: 0px 0px 2px 2px;
}
.logo-link {
  padding: 0;
}
.logo-link img {
  max-width: 175px;
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #537cff 0%, #3663f2 100%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
}
.avatar-load {
  background: #D9D9D9;
}
.dropdown-toggle {
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  color: #171a1f;
}
.dropdown-toggle::after {
  vertical-align: 0.16em;
}
.dropdown-menu {
  left: unset;
  top: unset;
}
.profile-name {
  line-height: 19px;
}
.profile-name-load
{
  width: 120px;
  height: 15px;
  background-color: #D9D9D9;
  border-radius: 4px;
}
.profile-props {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #71747a;
}
.profile-props-load
{
  width: 100px;
  height: 12px;
  background-color: #D9D9D9;
  border-radius: 4px;
}
.profile-props-value {
  margin-left: 10px;
}
.profile-props-star {
  margin-left: 3px;
}
.mobile-menu-icon {
  display: none;
}
.dropdown-item {
  padding: 0.5rem 1.5rem;
}
@media (max-width: 1400px) {
  .dropdown-menu {
    right: 40px;
  }
}
@media (max-width: 1200px) {
  .container {
    max-width: 100%;
  }
  .dropdown-menu {
    right: 10px;
  }
}

@media (max-width: 1024px) {
  .menu-link {
    font-size: 13px;
    margin: 0px 10px;
    padding: 10px 0;
  }
}
@media (max-width: 900px) {
  .navbar {
    padding: 5px 0;
  }
  .menu-link {
    display: none;
  }
  .mobile-menu-icon {
    display: block;
  }
  .registration-button {
    display: none;
  }
  .login-block {
    margin-right: 0px;
    display: flex;
  }
  .dropdown-menu {
    right: 10px;
  }
}

/* Basket small */
.basket-link,
.basket-link:hover {
  text-decoration: none;
}
.basket-load {
  width: 70px;
  height: 40px;
  background-color: #D9D9D9;
  border-radius: 4px;
  margin-right: 30px;
}
.basket-small {
  width: 70px;
  height: 40px;
  background-color: #436ef8;
  color: #fff;
  border-radius: 4px;
  margin-right: 30px;
}
.basket-empty-small {
  background-color: #fff;
  color: #71747a;
}
.basket-small__total-items {
  font-size: 17px;
  font-weight: 700;
}
</style>
