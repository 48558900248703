<template>
  <div class="notify-primary card" id="notify">
    <div class="notify-primary-body">
      <div class="notify-primary-pre-icon">
       <span class="material-icons info-icon">
      info
      </span>
      </div>
      <div class="notify-primary-text">
        <slot></slot>
      </div>
      <div class="notify-primary-close">
        <div class="notify-primary-close" onclick="document.getElementById('notify').style.display='none';">
          <span class="material-icons close-icon">
          close
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotifyPrimary"
}
</script>

<style scoped>
.info-icon {
  color: #4b75fb;
  font-size:22px;
}
.close-icon {
  font-size:16px;
}
.notify-primary {
  width:350px;
  background: rgba(54, 99, 242, 0.1);
  border: 1px solid rgba(54, 99, 242, 0.4);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(54, 99, 242, 0.2);
}
.notify-primary-body {
  display:flex;
  padding: 10px 15px;
}
.notify-primary-pre-icon {
  width: 30px;
  display: flex;
  align-items:center;
}
.notify-primary-text {
  width:290px;
  padding: 10px 0;
  font-size: 12px;
  color: #171A1F;
}
.notify-primary-close {
  width: 20px;
  display: flex;
  align-items:center;
  justify-content: center;
  cursor:pointer;
}
@media (max-width: 768px) {
  .notify-primary {
    width:100%;
  }
  .notify-primary-body {
    padding: 0 15px;
  }
  .notify-primary-text {
    width:100%;
  }
}
</style>