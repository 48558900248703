// import Axios from 'axios';
export const qr = {
    state: () => ({
        qr: '',
    }),
    mutations: {
        setQr(state, payload) {
            state.qr = payload
        },
    },
    actions: {
        checkQr(context, params) {
            context.commit('setDigitalCode', params);
            context.dispatch("checkSale", {
                'digitalCode': params
            }).then(() => {
                var information = context.getters.getSaleInformation;
                if (information.errors) {
                    context.commit('setErrors', [information.errors]);
                }
            });
        },
    },
    getters: {
        getQr: (state) => {
            return state.qr
        },
    }
}