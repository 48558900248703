import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [{
        path: '/',
        name: 'Home',
        // meta: {
        //     requiresAuth: true
        // },
        component: Home
    },
    {
        path: '/sale/',
        name: 'Sale',
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "SaleAdd" */ '../views/sale/Sale.vue'),
        children: [{
                path: '/sale/history/',
                name: 'SaleHistory',
                meta: {
                    requiresAuth: true
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "SaleHistory" */ '../views/sale/SaleHistory.vue')
            },
            {
                path: '/sale/add/',
                name: 'SaleAdd',
                meta: {
                    requiresAuth: true
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "SaleAdd" */ '../views/sale/SaleAdd.vue')
            },
            {
                path: '/sale/basket/',
                name: 'SaleBasket',
                meta: {
                    requiresAuth: true,
                    mainContainer: false,
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "SaleBasket" */ '../views/sale/SaleBasket.vue')
            },
        ],
    },
    {
        path: '/registration/',
        name: 'Registration',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "Registration" */ '../views/auth/Registration.vue')
    },
    {
        path: '/login/',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "Registration" */ '../views/auth/Login.vue')
    },
    {
        path: '/restore/',
        name: 'Restore',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "Restore" */ '../views/auth/Restore.vue')
    },
    {
        path: '/reset-password/',
        name: 'ResetPassword',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "ResetPassword" */ '../views/auth/ResetPassword.vue')
    },
    {
        path: '/summarizing/',
        name: 'Summarizing',
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "Summarizing" */ '../views/Summarizing.vue')
    },
    {
        path: '/scanner/qr/',
        name: 'ScannerQr',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "ScannerQr" */ '../views/ScannerQr.vue')
    },
    {
        path: '/about/',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "About" */ '../views/About.vue')
    },
    {
        path: '/faq/',
        name: 'Faq',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "Faq" */ '../views/Faq.vue')
    },
    {
        path: '/rules/',
        name: 'Rules',
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "Rules" */ '../views/Rules.vue')
    },
    {
        path: '/rules-public/',
        name: 'RulesPublic',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "RulesPublic" */ '../views/RulesPublic.vue')
    },
    {
        path: '/news/',
        name: 'News',
        component: () =>
            import ( /* webpackChunkName: "News" */ '../views/news/News.vue'),
        children: [{
                path: '',
                name: 'NewsList',
                component: () =>
                    import ( /* webpackChunkName: "NewsList" */ '../views/news/NewsList.vue')
            },
            {
                path: ':id/',
                name: 'NewsDetail',
                component: () =>
                    import ( /* webpackChunkName: "NewsDetail" */ '../views/news/NewsDetail.vue')
            }
        ],
    }
]

const router = createRouter({
    base: '/',
    history: createWebHashHistory(),
    routes
})

export default router