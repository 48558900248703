import Header from '@/components/Header.vue'
import Container from '@/components/Container.vue'
import Footer from '@/components/Footer.vue'
import Burger from '@/components/Menu/Burger.vue'
import Sidebar from '@/components/Menu/Sidebar.vue'
import Loading from '@/components/loading.vue'
import LoadingSmall from '@/components/loading-small.vue'
import Loadblock from '@/components/load-block.vue'

export default [
    Header,
    Container,
    Footer,
    Burger,
    Loading,
    LoadingSmall,
    Loadblock,
    Sidebar
]