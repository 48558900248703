<template>
  <nav class="navbar">
    <div class="navbar-container container">
      <div class="text-block">
        <div class="about-text mr-lg-5 mr-0">Сумма бонусов не ограничена!</div>
        <router-link to="/about/" class="menu-link">О программе</router-link>
        <router-link to="/faq/" class="menu-link">Вопрос-ответ</router-link>
      </div>
      <div class="d-flex align-items-center">
        <a href="mailto:info@blagadaru.store" class="main-color contacts mr-4">
          <span class="material-icons pr-1 contacts-icon">mail</span>
            info@blagadaru.store
        </a>
<!--        <a href="tel:+79990123456" class="main-color contacts">-->
<!--          <span class="material-icons pr-1 contacts-icon">call</span>-->
<!--          +7 (999) 012-34-56</a>-->
          <a target="_blank" href="https://silab.dev/" class="contacts">© 2022-2023 Copyright СИЛАБ</a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.contacts-icon {
  font-size: 20px;
}
.navbar {
  background: #e2edf8;
}
.menu-link {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #71747A;
  margin: 0px 20px;
  padding: 5px 0;
}
.menu-link:hover {
  text-decoration:none;
  color: #3663F2;
}
.about-text {
  color: #3663F2;
  font-size: 14px;
}

.contacts {
  font-weight: bold;
  font-size: 12px;
  display:flex;
}
.text-block {
  display:flex;
  align-items:center;
}
@media (max-width: 768px) {
  .about-text {
    display:none;
  }
}
</style>
