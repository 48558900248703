<template>
  <div class="text-block">
    <slot></slot>
  </div>

</template>

<script>
export default {
  name: "TextBlock"
}
</script>

<style scoped>
.text-block {
  background: #F8F8F8;
  border-radius: 9px;
  padding:25px;
}
</style>