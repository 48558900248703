<template>
  <div class="notify-danger card">
    <div class="notify-danger-body">
      <div class="notify-danger-pre-icon">
      <span class="material-icons error-icon">
      error_outline
      </span>
      </div>
      <div class="notify-danger-text">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotifyDanger"
}
</script>

<style scoped>
.error-icon {
  color: #a73939;
  font-size:22px;
}
.notify-danger {
  width:350px;
  background: #ffe6e6;
  border: 1px solid #e9a7a7;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 2px 4px #ffe6e6;
}
.notify-danger-body {
  display:flex;
  padding: 10px 15px;
}
.notify-danger-pre-icon {
  width: 30px;
  display: flex;
  align-items:center;
}
.notify-danger-text {
  width:320px;
  padding: 10px 0;
  font-size: 12px;
  color: #a73939;
}
@media (max-width: 768px) {
  .notify-danger {
    width:100%;
  }
  .notify-danger-body {
    padding: 0 15px;
  }
  .notify-danger-text {
    width:100%;
  }
}
</style>