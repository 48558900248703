<template>
  <div className="notify-success card mt-2">
    <div className="notify-success-body">
      <div className="notify-success-pre-icon">
      <span class="material-icons success-icon">
      check_circle_outline
      </span>
      </div>
      <div className="notify-success-text">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotifySuccess"
}
</script>

<style scoped>
.success-icon {
  color: #2b782d;
  font-size:22px;
}
.notify-success {
  width: 350px;
  background: #e1ffe2;
  border: 1px solid #8dc58f;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 2px 4px #e1ffe2;
}

.notify-success-body {
  display: flex;
  padding: 10px 15px;
}

.notify-success-pre-icon {
  width: 30px;
  display: flex;
  align-items: center;
}

.notify-success-text {
  width: 320px;
  padding: 10px 0;
  font-size: 12px;
  color: #2b782d;
}

@media (max-width: 768px) {
  .notify-success {
    width: 100%;
  }

  .notify-success-body {
    padding: 0 15px;
  }

  .notify-success-text {
    width: 100%;
  }
}
</style>
