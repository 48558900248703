<template>

    <div class="floating-label">
      <input class="floating-input" 
        :class="{'floating-input-error': error}"
        :required="required"
        v-model="input" 
        v-maska="maska"
        :name="name" 
        :type="type" 
        placeholder=" ">
      <label>{{ text }}</label>
      <small class="floating-text-error" v-if="required && error">Поле явлется обязательным</small>
    </div>

</template>

<script>
export default {
  data() {
    return {
      input: this.value,
    };
  },
  watch: {
    // при каждом изменении `select` эта функция будет запускаться
    input(newQuestion) {
      this.$emit('change', newQuestion)
    },
    value(newValue) {
      this.input = newValue
    },
  },
  name: "CustomInputWithLabel",
  props: {
    type: String,
    name: String,
    text: String,
    value: String,
    required: {
      type: Boolean,
      default: false
    },
    maska: {
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
.floating-label {
  position:relative;
  margin-bottom:20px;
}
.floating-input {
  font-size:14px;
  display:block;
  width:100%;
  border-radius: 8px;
  background-color: #f2f3f4;
  border:none;
  height: 54px;
  padding: 15px 15px 0px 15px;
  margin-top:15px;

}
.floating-input:focus {
  outline:none;
}
label {
  color:#999;
  font-size:14px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:15px;
  top:15px;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}
.floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
  top:5px;
  font-size:12px;
  color:#3663f2;
}


.floating-input{
    border: 1px solid transparent;
}
.floating-input-error{
    border-color: #d79090;
}
.floating-text-error{
  color: #cf1c1c;
  padding-left: 3px;
}
@media (max-width: 768px) {}
</style>
